import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import { ThemeContext } from './ThemeProvider';

const GOOGLE_FONTS_URL = 'https://fonts.googleapis.com/css2?' +
  'family=Noticia+Text:wght@700&family=Roboto+Mono:ital,wght@0,400;1,700&family=Zilla+Slab:wght@300;700&display=swap';

/**
 * Configures some extra properties on the head of the document.
 * 
 * This is not the header of the application.
 */
const Head = ({ title }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const { isDarkMode } = useContext(ThemeContext);
  const bodyClass = isDarkMode ? 'dark-theme' : '';

  return (
    <Helmet 
      title={`${title} | ${data.site.siteMetadata.title}`}
      bodyAttributes={{ class: bodyClass }}
    >
      <link rel="preconnect" href="https://fonts.googleapis.com"/>
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
      <link rel="stylesheet" href={GOOGLE_FONTS_URL} />
    </Helmet>
  );
};

export default Head;